import './LifeguardReportsTile.scss'
import PropTypes from "prop-types";
import {ReactComponent as ClipBoard} from "./clip-board.svg";
import {ReactComponent as DownArrow} from "./down-arrow.svg";
import {ReactComponent as UpArrow} from "./up-arrow.svg";
import { useState} from "react";
import {useTranslation} from "react-i18next";

const LifeguardReportsTile = props => {
    const [data] = useState(props.data || {})
    const [t] = useTranslation()

    return <div id={'lifeguard-reports'} className={props.isLast ? 'last' : ''}>
        <div className={'title'}>{t('lifeguardReports')}</div>
        <div className={'left-container'}>
            <ClipBoard className={'clip-board'} height={186}/>
            <div className={'hover-pane'}/>
            <div className={'major-title-container'}>
               <div className={'major-title'}>{data.value}</div>
               <div className={'sub-major-title'}>{t('reports')}</div>
           </div>
        </div>
        <div className={'right-container'}>
            {data.delta < 0 && <UpArrow className={'arrow-icon'}/>}
            {data.delta > 0 && <DownArrow className={'arrow-icon'}/>}
            {data.delta > 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('moreThanLastPeriod')}`}</div>}
            {data.delta < 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('lessThanLastPeriod')}`}</div>}
            {data.delta === 0 &&
                <div className={'bottom-label-lg'}>{`${Math.abs(data.delta)}% ${t('sameAsLastPeriod')}`}</div>}
        </div>
    </div>
}
LifeguardReportsTile.propTypes = {
    data: PropTypes.shape({id: PropTypes.number.isRequired,delta:PropTypes.number.isRequired, name: PropTypes.string.isRequired, value: PropTypes.number.isRequired}),
    isLast: PropTypes.bool.isRequired
}
export default LifeguardReportsTile