import './LanguageSelector.scss'
import React from 'react'

import {MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import Eng from './English.png'
import Ger from './German.png'
import Nor from './Norway.png'
import { useNavigate} from "react-router-dom";

const map = {
    "en": {name: "English", icon: Eng, value: 'en'},
    "nw": {name: "Norwegian", icon: Nor, value: 'nw'},
    "de": {name: "German", icon: Ger, value: 'de'}
}
const supportedLanguages = Object.values(map)
const LanguageSelector = props => {
    const [t, i18n] = useTranslation()
    const navigate = useNavigate();

    const handleLanguageSet = event => {
        navigate({search:`?lang=${event.target.value}`})
        localStorage.setItem('selectedLanguage', event.target.value);
        i18n.changeLanguage(event.target.value).then(() => {
        })
    }
    return <div id={'language-selector'}>
        <Select onChange={handleLanguageSet}
                disableUnderline
                defaultValue={i18n.language}
                IconComponent={()=>null}
                renderValue={(v) => <div className={'language-container'}><div className={'selected-language-button'}>{map[v].value.toUpperCase()}</div></div>}
                sx={{width: '100%', height: '100%', background: 'transparent !important', ':before': {borderBottomColor: 'transparent'},
                    ':hover': {borderBottomColor: 'white'}
                }}
                variant={'standard'}>
            {supportedLanguages.map(e => <MenuItem key={e.value} prop value={e.value}><div style={{paddingLeft:'15px'}}>{e.name}</div></MenuItem>
            )}
        </Select>
    </div>
}
export default LanguageSelector