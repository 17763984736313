import './ActiveWatchesTile.scss'
import PropTypes from "prop-types";
import { useState} from "react";
import {useTranslation} from "react-i18next";
import WatchRoundedIcon from '@mui/icons-material/WatchRounded';

const ActiveWatchesTile = props => {
    const [watches] = useState( props.activeWatches || [])
    const {t} = useTranslation();
    return <div id={'active-watches-tile'}>
        <div className={'title'}>{t('connectedWatches')}</div>
        <div className={'container'}>
            {watches.length? watches.map((e, i) =>
                <>
                <div className={'watch-item ' + ((i % 2 === 0) ? 'left-0' : 'right-0')} key={Math.random() + e.id}>
                    <div className={'dot'}/>
                    <WatchRoundedIcon className={'watch-icon'}/>
                    <div className={'watch-name'}>{e.id.substring(e.id.length - 5)}</div>
                    {i % 2 === 0 && i < watches.length - 1 ? <div className={'vertical-separator'}/> : null}
                </div>

                </>
            ):<div className={'empty-title'}>{t('noActiveWatches')}</div>}
        </div>
    </div>
}
ActiveWatchesTile.propTypes = {
    activeWatches: PropTypes.array.isRequired,
    poolId:PropTypes.number.isRequired

}
export default ActiveWatchesTile