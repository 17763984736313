import './UserMenu.scss'
import React, { useState } from 'react'
import SlideMenu from '../SlideMenu/SlideMenu';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {PropTypes} from "prop-types";

const UserMenu = props => {
    const [menuState, setMenuState] = useState(false)
    const [menuPos, setMenuPos] = useState(null)
    const handleMenuOpen = event => {
      setMenuPos(event.currentTarget)
      setMenuState(true)
    }

    const handleMenuState = (state) => {
      setMenuState(state)
    }


    return <div id={'menu-selector'}>
      <div className={'user-container'}>
        <MenuRoundedIcon onClick={handleMenuOpen} className={'burger-menu'}/>
        <SlideMenu open={menuState} menuPos={menuPos} handleMenuState={handleMenuState} organizationId={props.organizationId}/>
      </div>
    </div>
}
UserMenu.propTypes = {
    organizationId: PropTypes.number.isRequired
}
export default UserMenu