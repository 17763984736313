import {DateTime} from "luxon";
import {authorizeToken} from "../api/api";

export const shortString = (str, maxLength) => {
    if (!str) return ""
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '..'
    } else {
        return str
    }
}
export const languageToDateRangeFormat = {
    'en': 'en',
    'de': 'de',
    'nw': 'nb'
}
export const getNodeToken = () => {
    try {
        return JSON.parse(sessionStorage.getItem('node_context')).token
    } catch (ex) {
        console.log("Error parsing JSON: ", ex)
        return "No Token"
    }
}
function setNodeContext(context) {
    sessionStorage.setItem('node_context', JSON.stringify(context));
}
export const getNodeContext = () => {
    try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        let context
        if (token){
            authorizeToken(token).then(data => {
                context = data.data
                setNodeContext(context)
                window.location.href = '/';
            }).catch(ex =>{
                alert("🛑🛑🛑️ Error login in 🛑🛑🛑️")
            })
        }
        return JSON.parse(sessionStorage.getItem('node_context'))
    } catch (ex) {
        console.log("Error parsing JSON: ", ex)
        return {}
    }
}

export const getCurrentTime = (timezone, locale, formatString) => DateTime.now().setZone(timezone).setLocale(locale).toFormat(formatString)

/**
 *
 * @param value
 * @param timezone If timezone is empty we use node context timezone
 * @returns {{start, end: string}}
 */
export const getStartEndDates = (value, timezone = '') => {
    const nodeContext = getNodeContext()
    timezone = nodeContext.timezone
    const slicedStartValue = value.start.toISO().toLowerCase().endsWith('z') ? value.start.toISO().slice(0, -1) : value.start.toISO().slice(0, -6)
    const slicedEndValue = value.end.toISO().toLowerCase().endsWith('z') ? value.end.toISO().slice(0, -1) : value.end.toISO().slice(0, -6)
    const start = DateTime.fromISO(slicedStartValue, {zone: timezone}).toUTC().toISO()
    const end = DateTime.fromISO(slicedEndValue, {zone: timezone}).toUTC().toISO()
    return {start, end}
}

export const envMap = {'development': 'prod', 'staging': 'stag', 'production': 'prod'}
export const handleLogout = () => {
    sessionStorage.removeItem('node_context');
    window.location.href = '/';
}
