import {useState} from "react";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import './LoginPage.scss'
import Button from "@mui/material/Button";
import {Alert, TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab} from "@mui/material";
import {senAuthenticationEmail} from "../../api/api";

const LoginPage = props => {
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [isVisible, setIsVisible] = useState(false)
    const [loginType, setLoginType] = useState('username')
    const [email,setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errorState, setErrorState] = useState(false)
    const [completeFlow,setCompleteFlow] = useState(false)
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    const [t] = useTranslation()
    const handleWrongInput = () => {
        setErrorState(true)
    }
    const handleSubmit = async () => {
        try {
            if ('email' === loginType){
                if (!completeFlow && validateEmail(email)){
                    senAuthenticationEmail(email).then(e=>{
                        console.log(" Email sent to ",email)
                    })
                    setCompleteFlow(true)
                }
            }else{
                if (!username || !password) {
                    handleWrongInput()
                }
                const res = await props.submit(username, password)
                if (res === 0 || res > 201) {
                    setErrorMessage('Failed authentication')
                    setErrorState(true)
                } else {
                    return window.location.assign('/')
                }
            }

        } catch (ex) {
            setErrorMessage('Error logging in')
            setErrorState(true)

        }
    }
    const handleUsername = (e) => {
        setUsername(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleVisible = () => {
        setIsVisible(!isVisible)
    }
    const handleClose = () => {
        setErrorState(false)
    }

    function handleLoginType(event, value) {
        setLoginType(value)
        setCompleteFlow(false)
    }

    function handleEmailInput(e) {
        setEmail(e.target.value)
    }
    function validateEmail(email) {
        if (!email ||email.length <= 6){
            return false
        }
        const valid = email.indexOf('@')>1
            && email.indexOf('.')>1
            && email.indexOf('#') ===-1
            && email.indexOf('"') ===-1
            && email.indexOf("'") ===-1
            && email.indexOf("/") ===-1
            && email.indexOf("=") ===-1
            && email.indexOf("\\") ===-1
            && email.indexOf("eval(") ===-1
            && email.indexOf("(") ===-1
            && email.indexOf(")") ===-1
        return valid;
    }

    return <div id={'login-page'}>
        <Snackbar
            open={errorState}
            autoHideDuration={5000}
            onClose={handleClose}
            message={<span severity={'error'}  style={{background: 'unset', padding: 'unset', color: 'white',}}>{errorMessage}</span>}
            ContentProps={{sx: {backgroundColor: 'rgba(237,121,71,1)'}}}
            style={{borderRadius: 5, margin: 10, background: 'unset'}}
            anchorOrigin={{horizontal: 'center', vertical: "top"}}
        />
        <div className={'top-section'}>
            <div className={'title-container'}>
                <div className={'title-text'}>
                    {t('welcome')}
                </div>
                <div className={'sub-title-text'}>
                    {t('loginToAccount')}
                </div>
            </div>
            <Box sx={{width: '100%',paddingTop:'10px', typography: 'body1'}}>
                <TabContext value={loginType}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleLoginType}>
                            <Tab sx={{color: '#1f172a', textTransform:'none',fontSize:'1.15em',backgroundColor:loginType==='email'?'#F7F8FC':'#eef5ff'}} disableRipple label={t('password')} value='username'/>
                            <Tab sx={{color: '#1f172a',textTransform:'none',fontSize:'1.15em',backgroundColor:loginType==='email'?'#eef5ff':'#F7F8FC'}} disableRipple label={t('email')}  value='email'/>
                        </TabList>
                    </Box>
                    <TabPanel value="username">
                        <div className={'switch-container'}>
                            <div className={'title'}>{t("logInWithUser")}</div>
                        </div>
                        <div className={'form'}>
                            <div className={'mini-container paddingB'}>
                                <label>{t('username')}</label>
                                <input className={`input-field ${errorState ? 'errorState' : ''}`} onChange={handleUsername}
                                       type="text"/>
                            </div>
                            <div className={'mini-container'}>
                                <label>{t("password")}</label>
                                <input
                                       className={`input-field ${errorState ? 'errorState' : ''}`}
                                       onChange={handlePassword} type="password"/>
                            </div>
                            <div className={'fp-container'}>
                                <a className={'link-fp'} href="/reset_password">{t('forgotPassword')}</a>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="email">
                        <div className={'switch-container'}>
                            <div className={'title'}>{t("logInWithEmail")}</div>
                        </div>
                        <div className={'form height'}>
                            <div className={'mini-container '}>
                                <label>{t('email')}</label>
                                <input disabled={completeFlow} className={`input-field ${errorState ? 'errorState' : ''}`} onChange={handleEmailInput}
                                       type="text"/>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
            {/*<div className={'input-container'}>*/}
            {/*    <div className={'sub-container'}>*/}
            {/*        <input className={'basic-input'} onChange={handleUsername} type="text" required/>*/}
            {/*        <span className={'floating-label'}>*/}
            {/*        {t('username')}*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*    <div className={'sub-container'}>*/}
            {/*        <input className={'basic-input'} onChange={handlePassword} required*/}
            {/*               type={isVisible ? "text" : "password"}/>*/}
            {/*        <span className={'floating-label'}>*/}
            {/*            {t('password')}*/}
            {/*        </span>*/}
            {/*        <span className={'floating-visibility'} onClick={handleVisible}>*/}
            {/*            {isVisible ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}*/}
            {/*        </span>*/}
            {/*        <a className={'forgot-password-text'} href="/reset_password">*/}
            {/*            {t('forgotPassword')}*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        <div className={'bottom-section'}>
            <Button disabled={completeFlow} className={`login-button ${completeFlow?"disabled":""}`} onClick={handleSubmit}>
                <div className={'button-text'}>
                    {loginType==='email'?completeFlow?t('checkYourInbox'):t('sendEmail'):t('login')}
                </div>
            </Button>
            <div className={'bottom-label'}>
                <div className={'prefix'}>{t('poweredBy')}</div>
                &nbsp;
                {'Lynxight'}
            </div>
        </div>

    </div>
}
LoginPage.propTypes = {
    submit: PropTypes.func.isRequired
}
export default LoginPage