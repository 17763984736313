import './UnderConstructionPage.scss'

const UnderConstructionPage = ()=>{
return <div id={'under-construction-page'}>
    <div className={'text-area'}>
        <div className={'title'}>You are almost there…</div>
        <div className={'text'}>For the best experience, please try viewing the report on Desktop version</div>
    </div>
        <div className="sea">
            <div className="surface"></div>
        <div className="ship">
            <div className="rotate">
                <div className="move">
                    <div className="body">
                        <div className="waves">
                            <div className="bodywaves">
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                            </div>
                            <div className="oarwaves -left">
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                            </div>
                            <div className="oarwaves -right">
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                                <div className="wave">
                                    <div className="graphic"></div>
                                </div>
                            </div>
                        </div>
                        <div className="base"></div>
                        <div className="board -front"></div>
                        <div className="board -back"></div>
                    </div>
                    <div className="oars">
                        <div className="oar -left">
                            <div className="row -left">
                                <div className="depth -left">
                                    <div className="graphic -left"></div>
                                </div>
                            </div>
                        </div>
                        <div className="oar -right">
                            <div className="row -right">
                                <div className="depth -right">
                                    <div className="graphic -right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="human">
                        <div className="legs">
                            <div className="leg -left"></div>
                            <div className="leg -right"></div>
                        </div>
                        <div className="hat"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> }
export default UnderConstructionPage