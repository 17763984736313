import './HistoryPage.scss'
import {PropTypes} from "prop-types";
import SwimmersCountHistoryTile from "../SwimmerHistoryTile/SwimmerHistoryTile";
import SafetyEventsTile from "../SafetyEventsTile/SafetyEventsTile";
import LifeguardReportsTile from "../LifeguardReportsTile/LifeguardReportsTile";
import {useTranslation} from "react-i18next";
import {useState} from "react";

const HistoryPage = props => {
    const [t] = useTranslation();
    const [lifeguardData, setLifeguardData] = useState(props.lifeguardReportsData || [])
    const [swimmerHistoryData, setSwimmerHistoryData] = useState(props.swimmerHistoryData || [])
    const [safetyEventsData, setSafetyEventsData] = useState(props.safetyEventsData || [])

    return (
        <div id={'history-page'}>
            <div className={'main-container'}>
                <SafetyEventsTile data={safetyEventsData[props.index]} isLast={false}/>
                <LifeguardReportsTile isLast={false} data={lifeguardData[props.index]}/>
                <SwimmersCountHistoryTile isLast={true} data={swimmerHistoryData[props.index]}/>
            </div>
        </div>
    )
}
HistoryPage.propTypes = {
    safetyEventsData: PropTypes.array.isRequired,
    swimmerHistoryData: PropTypes.array.isRequired,
    lifeguardReportsData: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    getToken: PropTypes.func.isRequired,
}
export default HistoryPage;