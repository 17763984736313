import './RiskLevelTile.scss'
import {useState} from "react";
import React from 'react'
import PropTypes from "prop-types";
import Arrow from "./arrow.png";
import {useTranslation} from "react-i18next";

const RiskLevelTile = props => {
    const [riskValue] = useState(  props.riskValue)
    const [title] = useState(props.title)
    const [t] = useTranslation();
    const radius = 50
    const perimeterFull = Math.PI * radius * 2
    const arrowAngle = riskValue / 100 * 180 ? riskValue / 100 * 180 : 0
    const riskLevelCircle = riskValue ? (riskValue / 100) * ((perimeterFull / 2) - 5) : 0
    const barCx = 60
    const barCy = 100
    const riskLabelObj = riskValue <= 33 ? {
        title: 'lowRisk',
        color: '#57A6DB'
    } : riskValue <= 66 ? {title: 'mediumRisk', color: '#ED7847'} : {title: 'highRisk', color: '#D02E26'}
    return (
        <div id='risk-level-tile'
             key={'key-' + title + Math.random()}>
            <div className={'graph-area'}>
                <img className={'arrow-small'} src={Arrow} style={{
                    transition: 'all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms',
                    transform: `rotateZ(${arrowAngle}deg) translateX(0) translateY(0)`,
                    transformOrigin: '86% center'
                }} alt={"risk-arrow"}/>
                <div className={'graph-bar'}>
                    <svg className={'techometer'} fill="none" width="130" height="100">
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="110%" y2="0%">
                                <stop offset="5%" stopColor="#57a6db" />
                                <stop offset="40%" stopColor="#ED7847" />
                                <stop offset="100%" stopColor="#D02E26" />
                            </linearGradient>
                        </defs>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#F9F7F4"
                                strokeWidth="15" r={radius}></circle>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#EBEBEA"
                                strokeWidth="1" r={radius + 6}></circle>
                        <circle className="background" fill="none" cx={barCx} cy={barCy} stroke="#EBEBEA"
                                strokeWidth="1" r={radius - 6}></circle>
                        <circle className="techometer" fill="none" cx={barCx} cy={barCy} stroke="url(#gradient)"
                                strokeWidth="6" r={radius} strokeDasharray={[0,perimeterFull / 2 + 2.5,riskLevelCircle, perimeterFull]}
                                strokeDashoffset="-1"></circle>
                    </svg>
                </div>
            </div>
            <div className={'value-title-container'}>
                <div className={'value-title'} style={{color: riskLabelObj.color}}>{t(riskLabelObj.title)}</div>
            </div>
        </div>)
}
RiskLevelTile.propTypes = {
    title: PropTypes.string,
    riskValue: (props, propName, componentName) => (props[propName] < 0 || props[propName] > 100) && new Error('Invalid value provided, risk should 0-100 ')
}
export default RiskLevelTile