import {useTranslation} from "react-i18next";
import {useState} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import {PropTypes} from 'prop-types';
import './ResetPasswordNewCred.scss'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Button} from "@mui/material";

const ResetPasswordNewCred = props => {

    const [firstPassword, setFirstPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [blankFieldAlert, setBlankFieldAlert] = useState(false);
    const [dontMatchField, setDontMatchField] = useState(false);
    const [sessionExpried, setSessionExpired] = useState(false);
    const [isVisibleFirst, setIsVisibleFirst] = useState(false);
    const [isVisibleSecond, setIsVisibleSecond] = useState(false);
    const {token} = useParams();
    const [t] = useTranslation();

    const handleCode = async (e) => {
        if (firstPassword.length === 0 || secondPassword.length === 0) {
            setBlankFieldAlert(true);
            return;
        }
        if (firstPassword !== secondPassword) {
            setDontMatchField(true);
            return;
        }
        const url = `${process.env.REACT_APP_NODE_BE_PATH}/reset-password-token/reset-password`;
        const body = {password: firstPassword}
        try {
            await axios.patch(url, body, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            props.setIsPasswordEntered(true)
        } catch (e) {
            setSessionExpired(true);
            console.log("Error resetting password ", e.response)
            return e.response.status
        }
    }

    const handleFirstPassword = (e) => {
        setBlankFieldAlert(false);
        setDontMatchField(false);
        setFirstPassword(e.target.value)
    }

    const handleSecondPassword = (e) => {
        setBlankFieldAlert(false);
        setDontMatchField(false);
        setSecondPassword(e.target.value)
    }

    const handleVisibleFirst = () => {
        setIsVisibleFirst(!isVisibleFirst)
    }

    const handleVisibleSecond = () => {
        setIsVisibleSecond(!isVisibleSecond)
    }

    return (
        <div id={'reset-password-new-cred-page'}>
            <div className={'top-section'}>
                <div className={'text-pane'}>

                    <ArrowBackIosIcon className={'back-arrow'} onClick={() => {
                        window.location.assign('/')
                    }}/>
                    <div className={'title-text'}>
                        {t('resetPassword')}
                    </div>
                    <div className={'explain-text'}>
                        {t('resetPasswordNewPasswordsExplainText')}
                    </div>
                </div>
                <div className={'input-container'}>
                    <div className={'sub-container'}>
                        <input className={'basic-input'} onChange={handleFirstPassword} required
                               type={isVisibleFirst ? "text" : "password"}/>
                        <span className={'floating-label'}>{t('enterPassword')}</span>
                        <span className={'floating-visibility'} onClick={handleVisibleFirst}>
                        {isVisibleFirst ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                </span>
                    </div>
                    <div className={'sub-container'}>
                        <input className={'basic-input'} onChange={handleSecondPassword} required
                               type={isVisibleSecond ? "text" : "password"}/>
                        <span className={'floating-label'}>{t('confirmPassword')}</span>
                        <span className={'floating-visibility'} onClick={handleVisibleSecond}>
                        {isVisibleSecond ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                </span>
                        {blankFieldAlert &&
                            <div
                                className={'username-error'}>{t('Please enter the password in both input boxes.')}</div>}
                        {dontMatchField && <div className={'username-error'}>{t('Passwords don\'t match')}</div>}
                        {sessionExpried &&
                            <div className={'username-error'}>
                                {t('sessionExpired')}&nbsp;
                                <a href={window.location.href.substring(0, window.location.href.indexOf('reset_password')) + 'reset_password'}>{window.location.href.substring(0, window.location.href.indexOf('reset_password'))}reset_password</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={'bottom-section'}>
                <Button press onClick={handleCode} className={'back-button'}>
                    <div className={'button-text'}>
                        {t('changePassword')}
                    </div>
                </Button>

                <div className={'bottom-label'}>
                    <div className={'prefix'}>{t('poweredBy')}</div>
                    {'Lynxight'}
                </div>
            </div>

        </div>)
}
ResetPasswordNewCred.propTypes = {
    setIsPasswordEntered: PropTypes.func
}

const ResetPasswordFinish = props => {
    const [t] = useTranslation()
    const goBack = () => {
        window.location.assign('/')
    }
    return (
        <div id={'reset-password-new-cred-page'}>
            <div className={'top-section'}>
                <div className={'text-pane'}>
                    <div className={'title-text'}>
                        {t('passwordCreationSuccess')}
                    </div>
                    <div className='explain-text'>
                        {t('resetPasswordFinishExplainText')}
                    </div>
                </div>
            </div>
            <div className={'bottom-section'}>
                <Button className={'back-button'} onClick={goBack}>
                    <div className={'button-text'}>
                        {t('backToLoginPage')}
                    </div>
                </Button>
                <div className={'bottom-label'}>
                    <div className={'prefix'}>{t('poweredBy')}</div>
                    {'Lynxight'}
                </div>
            </div>
        </div>)
}

const ResetPasswordCredFull = props => {
    const [isPasswordEntered, setIsPasswordEntered] = useState(false);
    return (<div>
        {!isPasswordEntered && <ResetPasswordNewCred setIsPasswordEntered={setIsPasswordEntered}/>}
        {isPasswordEntered && <ResetPasswordFinish/>}
    </div>)
}

export default ResetPasswordCredFull;
