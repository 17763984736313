import './PoolDiagramTile.scss'
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useState} from "react";
import {transformLinearVector} from "../../Utils/LinearCalcHelper";
import {envMap} from "../../Utils/utils";

const url = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'pools/' + envMap[process.env.NODE_ENV] + '/'
const transformationDimensions = {height: 710, width: 1380}
const height = 250
const layoutDimensions = {height, width: height * transformationDimensions.width / transformationDimensions.height}
const adjustmentHeight = 0;
const adjustmentWidth = 0;
const PoolDiagramTile = props => {
    const [transformation] = useState(props.transformation)
    const [locations] = useState(props.locations || [])
    const {t} = useTranslation();
    const scale = {
        x: (layoutDimensions.width) / transformationDimensions.width,
        y: (layoutDimensions.height) / transformationDimensions.height
    }

    function handleImageError() {
        const img = document.getElementById('pool-image')
        img.src = url + `pool_id_0.svg`
    }

    const swimmersLocationsTransformed = locations.map(l => transformLinearVector(transformation, l))

    return <div id={'pool-diagram-tile'} className={'last'}>
        <div className={'pool-tile-title'}>{t('swimmersLocations')}</div>
        <div className={'inner-box-pd'}>
            <div className={'pool-image-container'}>
                <img id={'pool-image'} className={'img-pool'} src={url + 'pool_id_' + props.poolId + '.svg'}
                     onError={handleImageError} alt="Pool-Image"/>
                <div id={'pool-canvas'} className={'swimmers-layout'}>
                    {swimmersLocationsTransformed.map(coordinate => {
                            let top = adjustmentHeight + (scale.y * coordinate.y)
                            let left = adjustmentWidth + (scale.x * coordinate.x)
                            if (top > layoutDimensions.height) {
                                top = layoutDimensions.height
                            }
                            if (left > layoutDimensions.width) {
                                left = layoutDimensions.width
                            }
                            if (left < 0) {
                                left = 0

                            }
                            if (top < 0) {
                                top = 0
                            }
                            return <div key={Math.random() + '-key'} className={'dot'} style={{
                                top: `${top}px`,
                                left: `${left}px`
                            }}></div>
                        }
                    )}
                </div>
            </div>
        </div>
    </div>
}
PoolDiagramTile.propTypes = {
    locations: PropTypes.array.isRequired,
    poolId: PropTypes.number.isRequired,
    transformation: PropTypes.array.isRequired,
}
export default PoolDiagramTile