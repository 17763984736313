import './SwipeTabs.scss'
import {PropTypes} from 'prop-types';
import {useState} from "react";
import 'swiper/css';
import {useTranslation} from "react-i18next";
import LivePage from "../LivePage/LivePage";
import HistoryPage from "../HistoryPage/HistoryPage";
import GeneralLivePage from "../GeneralLivePage/GeneralLivePage";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';
import {ReactComponent as LiveIcon} from "../GeneralLivePage/live-icon.svg";
import {ReactComponent as HistoryIcon} from "../HistoryPage/history-icon.svg";
import DateSelector from "../DateSelector/DateSelector";

SwiperCore.use([Controller])
const SwipeTabs = props => {
    const [headerSwiper, setHeaderSwiper] = useState(null);
    const [pageSwiper, setPageSwiper] = useState(null)
    const [isLiveView, setIsLiveView] = useState(true) // true live view, false history view
    const [t] = useTranslation()
    const liveData = props.children.liveData.length === 2 ? props.children.liveData.filter(e => e.id !== -1) : props.children.liveData


    const handleSetLiveView = () => {
        setIsLiveView(true)
    }
    const handleSetHistoryView = () => {
        setIsLiveView(false)
    }
    const slideToPool = (index) => {
        pageSwiper.slideTo(index + 1)
    }
    const renderItem = (item, index) => {
        return (
            <SwiperSlide key={Math.random() + '#' + item.id} onClick={() => slideToPool(index - 1)}>
                {!item.general &&
                    <span className={'dot'} style={{background: item.status === 'online' ? 'green' : 'red'}}/>
                }
                <span className={'header-pool-name'}>{item.dashboard_name}</span>

            </SwiperSlide>
        )
    }
    return (
        <div id={'swipe-tabs'}>
            <div className={'swipe-tabs-header'}>
                <Swiper
                    className={'items'}
                    spaceBetween={0}
                    slidesPerView={3}
                    onSwiper={setHeaderSwiper}
                    controller={{control: pageSwiper}}
                    initialSlide={0}
                    centeredSlides={true}
                    direction={'horizontal'}
                    effect={'fade'}
                    normalizeSlideIndex={true}
                >
                    {
                        liveData.map((item, index) => {
                            return renderItem(item, index)
                        })
                    }
                </Swiper>
            </div>
            <div className={'view-container'}>
                <div className={'view-title'}>
                    {isLiveView ? <LiveIcon/> : <HistoryIcon/>}
                    <div className={'view-text'}>
                        {t(isLiveView ? 'live' : 'history')}
                    </div>
                </div>
                {isLiveView ? null : <DateSelector key={'idk'+Math.random()} setTimeFrame={props.handleSetTimeFrame} timeFrame={props.timeFrame}/>}
            </div>
            <div className={'main-pane'}>
                <Swiper
                    className={'pages'}
                    id={'main-pane-pages-id'}
                    spaceBetween={40}
                    slidesPerView={1}
                    onSwiper={setPageSwiper}
                    controller={{control: headerSwiper}}
                    initialSlide={0}
                    centeredSlides={true}
                    direction={'horizontal'}
                    height={"100%"}
                    effect={'fade'}
                    normalizeSlideIndex={true}
                    observeSlideChildren={true}
                >
                    {liveData.map((item, index) => {
                        return <SwiperSlide key={Math.random() + '^key'}>
                            {isLiveView && item ?
                                (item.general ? <GeneralLivePage handleGoToPool={slideToPool} data={liveData} getToken={props.getToken}/> :
                                    <LivePage data={item} getToken={props.getToken}/>)
                                :
                                <HistoryPage getToken={props.getToken} safetyEventsData={props.children.safetyEventsData}
                                             swimmerHistoryData={props.children.swimmerHistoryData}
                                             lifeguardReportsData={props.children.lifeguardReportsData} index={index}/>
                            }
                        </SwiperSlide>
                    })}
                </Swiper>
                <div className={'change-view-button'} id={'change-view-button-id'}>
                    <div className={`view ${isLiveView ? 'active' : ''}`} onClick={handleSetLiveView}>{t('live')}</div>
                    <div className={'divider'}/>
                    <div className={`view ${!isLiveView ? 'active' : ''}`}
                         onClick={handleSetHistoryView}>{t('history')}</div>
                </div>
            </div>

        </div>
    )
}

SwipeTabs.propTypes = {
    children: PropTypes.object.isRequired,
    getToken: PropTypes.func.isRequired,
    timeFrame: PropTypes.object.isRequired,
    handleSetTimeFrame: PropTypes.func.isRequired,
};

export default SwipeTabs;