import './GeneralLivePage.scss'
import {useTranslation} from "react-i18next";
import LiveStatus from "../LiveStatus/LiveStatus";
import {PropTypes} from "prop-types";
import PoolGeneralTile from "../PoolGeneralTile/PoolGeneralTile";
import {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {fetchSiteLiveData} from "../../api/api";
import {getNodeContext} from "../../Utils/utils";

const GeneralLivePage = props => {
    const [t] = useTranslation();
    const [data, setData] = useState(props.data[0] || {})
    const [completeData, setCompleteData] = useState(props.data.filter(pool => pool.id !== -1) || [])
    const nodeContext = useMemo(() => getNodeContext(), [])
    const setLiveData = () => {
        fetchSiteLiveData(nodeContext.siteId).then((_liveData) => {
            setData(_liveData[0])
            setCompleteData(_liveData.filter((item) => item.id !== -1))
        })
    }
    useEffect(() => {
        const interval = setInterval(setLiveData, 10 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])


    return (
        <div id={'general-live-page'}>
            <LiveStatus swimmerCount={data.content.swimmersCount}
                        watchesCount={data.content.activeWatches}/>
            <div className={'main-container'}>
                {completeData.map((item, index) => {
                    if (item.id === -1) return null;
                    return (
                        <PoolGeneralTile handleGoToPool={props.handleGoToPool} index={index}
                                         key={Math.random() + "-key"} style={{marginBottom: '100px'}}
                                         poolName={item.dashboard_name}
                                         swimmersCount={item.content.locations.length} capacity={item.content.capacity}
                                         riskValue={item.content.riskLevel} isGeneral={true}
                                         isLast={index === completeData.length - 1}/>
                    )
                })}
            </div>
        </div>
    )
}
GeneralLivePage.propTypes = {
    data: PropTypes.array.isRequired,
    getToken: PropTypes.func.isRequired,
    handleGoToPool: PropTypes.func.isRequired
};
export default GeneralLivePage;