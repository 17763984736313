import './Header.scss'
import UserTitle from '../UserTitle/UserTitle';
import UserMenu from '../UserMenu/UserMenu'
import PropTypes from 'prop-types';
import Notifications from "../Notifications/Notifications";
const Header = props => {
    return (
        <div id={'main-header'}>
            <Notifications/>
            <UserTitle username={props.context.username} orgName={props.context.orgName} siteName={props.context.siteName}/>
            <UserMenu organizationId={props.context.orgId}/>
        </div>
    )
}
Header.propTypes = {
    logoLink: PropTypes.string.isRequired,
    context: PropTypes.object.isRequired,
    localTime: PropTypes.string.isRequired,
}
export default Header