import './UserTitle.scss'
import React  from 'react';
import PropTypes from "prop-types";

const UserTitle = props => {
    return <div id={'company-name'}>
        <div className={'user-title'}>{props.siteName || 'Site-name'}</div>
    </div>
}
UserTitle.propTypes = {
    siteName: PropTypes.string.isRequired,
}
export default UserTitle