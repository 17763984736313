import './DateSelector.scss';
import {PropTypes} from "prop-types";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import moment from 'moment'
import {useState} from "react";
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {useTranslation} from "react-i18next";
import useComponentVisible from "../../Utils/CustomHook";
import {DateTime} from "luxon";

const uiDisplayFormat = 'dd-MMM-y'
const pallet = ['#57A6DB']

const DateSelector = props => {
    const [currentDate, setCurrentDate] = useState([
        {
            startDate: props.timeFrame.start.toJSDate(),
            endDate: props.timeFrame.end.toJSDate(),
            key: 'selection'
        }
    ]);

    const setChangeViewVisible = () => {
        document.getElementById('change-view-button-id').style.zIndex = '1'
        document.getElementById('main-pane-pages-id').style.zIndex = '0'
    }
    const [t, tObj] = useTranslation()
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false, setChangeViewVisible);
    const [isFirst, setIsFirst] = useState(true)
    const toggleMenu = () => {
        setIsComponentVisible((!isComponentVisible))
    }
    const handleMenuClose = () => {
        setIsComponentVisible(false)
    }
    const handleChangeDateRange = (ranges) => {
        if (isFirst) {
            if (ranges.selection.endDate < ranges.selection.startDate)
                setCurrentDate([{
                    startDate: ranges.selection.endDate,
                    endDate: ranges.selection.startDate,
                    key: 'selection'
                }])
            else
                setCurrentDate([ranges.selection])
        } else {
            if (ranges.selection.endDate < currentDate[0].startDate)
                setCurrentDate([{startDate: ranges.selection.endDate, endDate: currentDate[0].startDate, key: 'selection'}])
            else
                setCurrentDate([{startDate: currentDate[0].startDate, endDate: ranges.selection.endDate, key: 'selection'}])
        }
        setIsFirst(!isFirst)
    }
    const cancelSelectedDateAndTime = () => {
        setCurrentDate([{
            startDate: props.timeFrame.start.toDate(),
            endDate: props.timeFrame.end.toDate(),
            key: 'selection'
        }]);
        handleMenuClose();
    }
    const submitSelectedDateAndTime = () => {
        const data = currentDate[0]
        props.setTimeFrame({
            start: DateTime.fromISO(DateTime.fromJSDate(data.startDate).toISO().slice(0, -6) + 'Z').startOf('day'),
            end: DateTime.fromISO(DateTime.fromJSDate(data.endDate).toISO().slice(0, -6) + 'Z').endOf('day')
        })
        handleMenuClose();
    }

    return (
        <div key={'date-selector' + Math.random()} id={'date-selector'}>
            <div className={'calendar-title'} onClick={toggleMenu}>
                <CalendarTodayOutlinedIcon className={'date-selector-icon'}/>
                <div key={'calendar-title1' + Math.random()}
                     className={'date-value start'}>
                    {DateTime.fromJSDate(currentDate[0].startDate).toFormat(uiDisplayFormat)}
                </div>
                <ArrowRightAltIcon className={'date-icon-arrow'}/>
                <div key={'calendar-title2' + Math.random()}
                     className={'date-value end'}>
                    {DateTime.fromJSDate(currentDate[0].endDate).toFormat(uiDisplayFormat)}
                </div>
            </div>
            {isComponentVisible && <div
                id={'date-selector-container'}
                ref={ref}
            >
                <DateRange
                    className={'date-range'}
                    months={2}
                    showDateDisplay={false}
                    editableDateInputs={false}
                    onChange={handleChangeDateRange}
                    ranges={currentDate}
                    rangeColors={pallet}
                    fixedheight
                    moveRangeOnFirstSelection={false}
                />
                <div className={'bottom-container'}>
                    <div className={'button cancel'} onClick={cancelSelectedDateAndTime}>{t('cancel')}</div>
                    <div className={'button submit'} onClick={submitSelectedDateAndTime}>{t('setDate')}</div>
                </div>
            </div>}
        </div>
    );
}

DateSelector.propTypes = {
    setTimeFrame: PropTypes.func.isRequired,
    timeFrame: PropTypes.object.isRequired,
}

export default DateSelector;