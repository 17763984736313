import './Dashboard.scss'
import {useEffect, useMemo, useState} from "react";
import Header from "../Header/Header";
import {useNavigate} from "react-router-dom";
import SwipeTabs from "../SwipeTabs/SwipeTabs";
import {getNodeContext, getNodeToken, getStartEndDates} from "../../Utils/utils";
import {
    fetchSiteLifeguardReports,
    fetchSiteLiveData,
    fetchSiteSafetyEvents,
    fetchSiteSwimmerHistory
} from "../../api/api";
import {DateTime} from "luxon";


const Dashboard = props => {
    const [combinedData, setCombinedData] = useState({
        safetyEventsData: [],
        liveData: [],
        swimmerHistoryData: {data: []},
        lifeguardReportsData: []
    })
    const nodeContext = useMemo(getNodeContext, [])
    const [timeFrame, setTimeFrame] = useState({start: DateTime.now().startOf('day'), end: DateTime.now().endOf('day')})
    const navigate = useNavigate();

    const getAllData = ({start, end}, siteId) => {
        Promise.all([
            fetchSiteLiveData(siteId),
            fetchSiteLifeguardReports(siteId, start, end),
            fetchSiteSwimmerHistory(siteId, start, end),
            fetchSiteSafetyEvents(siteId, start, end)
        ]).then(([
                     _liveData,
                     _lifeguardData,
                     _swimmerHistoryData,
                     _safetyEventsData
                 ]) => {
            console.log("Fetched Data =>>  _liveData, _lifeguardData, _historyData, _safetyData", _liveData, _lifeguardData, _swimmerHistoryData, _safetyEventsData);
            setCombinedData({
                liveData: _liveData,
                safetyEventsData: _safetyEventsData,
                swimmerHistoryData: _swimmerHistoryData,
                lifeguardReportsData: _lifeguardData
            })
        }).catch(ex => {
            console.log("Error fetching live data in useEffect interval: ", ex)
        })
    }

    useEffect(() => {
        navigate('/dashboard')
        const {start, end} = getStartEndDates({start: DateTime.utc().toUTC().startOf('day'), end: DateTime.utc().toUTC().endOf('day')})
        const context = getNodeContext() || {}
        const siteId = context.siteId
        getAllData({start, end}, siteId)
    }, [])

    const handleSetTimeFrame = (value) => {
        const siteId = nodeContext.siteId
        getAllData(getStartEndDates(value, nodeContext.timezone), siteId)
        setTimeFrame(value)
    }

    return <div id={'dashboard-page'}>
        <Header logoLink={'link'} context={getNodeContext()} localTime={''}/>
        <SwipeTabs handleSetTimeFrame={handleSetTimeFrame} timeFrame={timeFrame} getToken={getNodeToken}
                   children={combinedData}/>
    </div>
}
Dashboard.propTypes = {}
export default Dashboard