import {useState} from 'react';
import {useTranslation} from "react-i18next";
import ConfirmLogoutDialog from "./ConfirmLogoutDialog";
import './Logout.scss';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

const Logout = props => {

    const [t] = useTranslation()
    const [confirmWindow, setConfirmWindow] = useState(false)

    const handleOpenConfirmWindow = () => {
        setConfirmWindow(true)
    }

    const handleCloseConfirmWindow = () => {
        setConfirmWindow(false)
    }

    return (
        <div id={'logout-pane'}>
            <LogoutRoundedIcon/>
            <div className={'plain-text'} onClick={handleOpenConfirmWindow}>{t('logout')}</div>
            <ConfirmLogoutDialog openState={confirmWindow} handleConfirmClose={handleCloseConfirmWindow}/>
        </div>)
}


export default Logout;