import axios from "axios";
import {getNodeToken, handleLogout} from "../Utils/utils";
function processError(error) {
    console.warn("Error occurred\n ================================\n", error)
    if (error && error.request && error.request.status === 401){
        console.warn("Token expired\n ================================\n need to login again", )
        handleLogout()
    }
}

// Node api
export const senAuthenticationEmail = async (email) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_NODE_BE_PATH}/login/email`, {email},)
        return res.data.status
    } catch (e) {
        console.log('Error ', e)
        return []
    }
}
export const authorizeToken = async (token = -1) => {
    const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/login/authorize`,
        {
            headers: {'Authorization': `Bearer ${token}`}
        })
    return res.data
}
export const fetchSiteLiveData = async (siteId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/live-data/${siteId}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.pools
    } catch (e) {
        console.log('Error  in -> fetchSiteLiveData', e)
        processError(e)
        return []
    }

}

export const fetchSiteSwimmerHistory = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/swimmer-history/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSwimmerHistory', e)
        processError(e)
        return []
    }

}

export const fetchSiteSwimmerHistoryFilterByPoolId = async (siteId, start, end, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/swimmer-history/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.find(e => e.id === poolId) || {}
    } catch (e) {
        console.log('Error  in -> fetchSiteSwimmerHistoryFilterByPoolId', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEvents = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEvents', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEventsFilterByPoolId = async (siteId, start, end, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data.find(e => e.id === poolId) || {}
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsFilterByPoolId', e)
        processError(e)
        return []
    }

}

export const fetchSiteLifeguardReports = async (siteId, start, end) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports/${siteId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReports', e)
        processError(e)
        return []
    }

}

export const fetchPoolHeatmapData = async (start, end, poolId) => {

    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/heatmap/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchPoolHeatmapData', e)
        processError(e)
        return []
    }
}
export const fetchSiteSafetyEventsTableData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events-table/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsTableData', e)
        processError(e)
        return []
    }

}

export const fetchSiteSafetyEventsGraphData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/safety-events-graph/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteSafetyEventsGraphData', e)
        processError(e)
        return []
    }

}

export const fetchSiteLifeguardReportsTableData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports-table/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReportsTableData', e)
        processError(e)
        return []
    }

}

export const fetchSiteLifeguardReportsGraphData = async (start, end, siteId, poolId = -1) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/site/lifeguard-reports-graph/${siteId}/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchSiteLifeguardReportsGraphData', e)
        processError(e)
        return []
    }
}

export const fetchPoolConnectedWatchesGraphData = async (start, end, poolId) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_NODE_BE_PATH}/pool/connected-watches-graph/${poolId}?startTime=${start}&endTime=${end}`, {
            headers: {'Authorization': `token ${getNodeToken()}`}
        })
        return res.data.data
    } catch (e) {
        console.log('Error  in -> fetchPoolConnectedWatchesGraphData', e)
        processError(e)
        return []
    }
}

