// const transformAndScale=(_matrix,_vector,_scale)=>{
//     if (!_scale || !_scale.length){
//         return transform(_matrix,_vector)
//     }
// }
const transform = (_matrix, _vector) => {
    const x = _matrix[0][0] * _vector.x + _matrix[0][3]
    const y = _matrix[1][1] * _vector.y + _matrix[1][3]
    return {x:Math.round(x),y:Math.round(y)}
}

const transformLinearVector = (matrix, vector)=>{
    vector.z = 1
    vector.w= 1
    let c0r0 = matrix[0][ 0], c1r0 = matrix[0][ 1], c2r0 = matrix[0][ 2], c3r0 = matrix[0][ 3];
    let c0r1 = matrix[1][ 0], c1r1 = matrix[1][ 1], c2r1 = matrix[1][ 2], c3r1 = matrix[1][ 3];
    let c0r2 = matrix[2][ 0], c1r2 = matrix[2][ 1], c2r2 = matrix[2][2], c3r2 = matrix[2][3];

    let x = vector.x;
    let y = vector.y;
    let z = vector.z;
    let w = vector.w;

    let resultX = (x * c0r0) + (y * c0r1) + (z * c0r2) + (w*c3r0);
    let resultY = (x * c1r0) + (y * c1r1) + (z * c1r2) + (w * c3r1);
    let resultZ = (x * c2r0) + (y * c2r1) + (z * c2r2) + (w * c3r2);

    return {x: Math.round(resultX), y: Math.round(resultY), z: Math.round(resultZ)}

}

module.exports={transform,transformLinearVector}