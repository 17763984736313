import {Drawer} from "@mui/material";
import './SlideMenu.scss';
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import {PropTypes} from "prop-types";
import {envMap, getNodeContext} from "../../Utils/utils";
import DateAndTime from "../DateAndTime/DateAndTime";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Logout from "../Logout/Logout";
import {useMemo} from "react";

const baseLogoUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'icons/' + envMap[process.env.NODE_ENV] + '/'
const SlideMenu = props => {
    const context = useMemo(getNodeContext, [])
    function handleImageError() {
        const img = document.getElementById('company-logo')
        if (img.src === baseLogoUrl + `icon_org_id_lx.svg`)
            return

        img.src = baseLogoUrl + `icon_org_id_lx.svg`
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.handleMenuState(open);
    };

    return (
        <Drawer
            PaperProps={{
                sx: {
                    width: "76%", background: "#FFFFFF",
                    boxShadow: "-4px 0px 16px rgba(30, 24, 42, 0.12)",
                    borderRadius: "0px",
                    overflowX: 'hidden', overflowY: 'hidden'
                },
            }}
            variant={'temporary'}
            anchor={'right'}
            open={props.open}
            onClose={() => {
                props.handleMenuState(false)
            }}
        >
            <div id={'slide-menu'}>
                <div className={'header'}>
                    <img id={'company-logo'} className={'company-icon'}
                         src={baseLogoUrl + `icon_org_id_${props.organizationId}.svg`}
                         onError={handleImageError} alt={'Company-icon'}/>
                    <Button className={'close-button'} onClick={toggleDrawer(false)}>
                        <CloseIcon/>
                    </Button>
                </div>
                <div className={'row'}>
                    <DateAndTime timezone={context.timezone}/>
                    <LanguageSelector/>
                </div>
                <div className={'logout'}>
                    <Logout/>
                </div>
            </div>
        </Drawer>)
}
SlideMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    organizationId: PropTypes.number.isRequired,
    handleMenuState: PropTypes.func.isRequired
}

export default SlideMenu;