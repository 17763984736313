import './App.scss';
import axios from "axios";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./Components/LoginPage/LoginPage";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import ResetPasswordFull from "./Components/ResetPassword/ResetPassword";
import ResetPasswordCredFull from "./Components/ResetPassword/ResetPasswordNewCred";
import {hotjar as TagManager, hotjar} from 'react-hotjar';
import {getNodeContext} from "./Utils/utils";
import {useEffect} from "react";
import UnderConstructionPage from "./Components/UnderConstractionPage/UnderConstractionPage";
import Page404 from "./Components/Page404/Page404";

function App() {
    useEffect(() => {
        process.env.NODE_ENV === 'production' && TagManager.initialize({gtmId: 'G-V0BG4TCD13'});
    }, [])
    console.log("Environment ", process.env.NODE_ENV)
    console.log("Node-Be Path ", process.env.REACT_APP_NODE_BE_PATH, process.env)
    console.log("Init hotjar with key", process.env.REACT_APP_HOTJAR_ID || '#no key#')
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)
    const authenticateNode = async (username, password) => {
        const url = `${process.env.REACT_APP_NODE_BE_PATH}/login`
        const body = {username, password}
        const res = await axios.post(url, body)
        const _context = {
            poolsIds: res.data.data.pools,
            ...res.data.data
        }
        delete _context.pools
        setNodeContext(_context);
        return _context
    }
    const authenticate = async (username, password) => {
        const context = await authenticateNode(username, password)
        try {
            hotjar.identify(username, {
                email: context.email || '',
                site: context.siteName || "none",
                organization: context.orgName || "none",
                isStaff: context.isStaff,
                environment: process.env.NODE_ENV
            })
        } catch (e) {
            console.log("Error authenticating ", e.response)
            return e.response.status
        }

    }

    function setNodeContext(context) {
        sessionStorage.setItem('node_context', JSON.stringify(context));
    }


    if (!getNodeContext()) {
        return <BrowserRouter id='app'>
            <Routes>
                <Route path="/" element={<LoginPage submit={authenticate}/>}/>
                <Route path="dashboard" element={<LoginPage submit={authenticate}/>}/>
                <Route path="reports" element={<UnderConstructionPage/>}/>
                <Route path="reset_password" element={<ResetPasswordFull/>}/>
                <Route path="reset_password/:token" element={<ResetPasswordCredFull/>}/>
                <Route path="*" element={<Page404/>}/>
            </Routes>
        </BrowserRouter>
    }
    return (
        <div className="App">
            <BrowserRouter id='app'>
                <Routes>
                    <Route path="/" element={<Dashboard/>}/>
                    <Route path="reports" element={<UnderConstructionPage/>}/>
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="reset_password" element={<ResetPassword/>}/>
                    <Route path="*" element={<Page404/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
