import './PoolGeneralTile.scss'
import PropTypes from "prop-types";
import RiskLevelTile from "../RiskLevelTile/RiskLevelTile";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const PoolGeneralTile = props => {
    const [riskValue] = useState(props.riskValue)
    const [poolName] = useState(props.poolName)
    const [swimmersCount] = useState(props.swimmersCount)
    const [capacity] = useState(props.capacity)
    const [t] = useTranslation()
    const radius = 52;
    const perimeterFull = Math.PI * radius * 2
    const maxThickness = 10
    const thickness = 4
    const delimiter = 15
    const circleCx = radius + thickness + 1
    const circleCy = radius + thickness + 1
    const countOfNonZeroValues = (swimmersCount !== undefined && !!swimmersCount ? 1 : 0)
    const swimmerCountLine = swimmersCount > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * capacity
    return <div id={'pool-general-tile'} className={props.isLast ? 'last' : ''}>
        <div className={props.isGeneral ? 'title general' : 'title'} onClick={props.isGeneral ? () => {props.handleGoToPool(props.index)} : () => {}}>{poolName || t('riskLevel')}</div>
        <div className={'main-container'}>
            <RiskLevelTile key={Math.random()+"-key"} riskValue={riskValue} title={poolName}/>
            <div className={'vertical-separator'}/>
            <div className={'right-label-container'}>
                <svg id={'svg-area'} className={'circle-chart'}>
                    <g strokeLinecap="round">
                        <svg fill="none" width="200" height="200">
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#F9F7F4"
                                    strokeWidth={maxThickness} r={radius}></circle>
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#EBEBEA"
                                    strokeWidth="1" r={radius + 5}></circle>
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#EBEBEA"
                                    strokeWidth="1" r={radius - 5}></circle>
            
                            {swimmerCountLine &&
                                <circle className="percentage" fill="none" cx={circleCx} cy={circleCy} stroke="#57A6DB" style={{boxShadow: 'inset 0px 0px 4px rgba(14, 19, 37, 0.12)'}}
                                        strokeWidth={thickness} r={radius} strokeDasharray={[swimmerCountLine, perimeterFull]}
                                        strokeDashoffset={0}></circle>}
                        </svg>
                    </g>
                </svg>
                <div className={'general-label-container'}>
                    <div className={'risk-main-title'}>{swimmersCount}</div>
                    <div className={'risk-sub-title'}>{t('swimmers')}</div>
                </div>
            </div>
        </div>
    </div>
}
PoolGeneralTile.propTypes = {
    poolName: PropTypes.string.isRequired,
    swimmersCount: PropTypes.number.isRequired,
    riskValue: PropTypes.number.isRequired,
    isGeneral: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    handleGoToPool: PropTypes.func.isRequired,
    capacity: PropTypes.number.isRequired
}
export default PoolGeneralTile