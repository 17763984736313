import './LiveStatus.scss';
import WatchRoundedIcon from '@mui/icons-material/WatchRounded';
import {ReactComponent as SwimmerIcon} from "./totalSwimmersIcon.svg";
import {PropTypes} from "prop-types";
import {useTranslation} from "react-i18next";

const LiveStatus = props => {
    const [t] = useTranslation()
    return (
        <div id={'live-status-pane'}>
            <div className={'status-container'}>
                <div className={'icon-bg'}>
                    <WatchRoundedIcon/>
                </div>
                <div className={'plain-text'}>
                    {t('activeWatches')}
                </div>
                <div className={'number-text'}>
                    {props.watchesCount}
                </div>
            </div>
            <div className={'status-container'}>
                <div className={'icon-bg'}>
                    <SwimmerIcon/>
                </div>
                <div className={'plain-text'}>
                    {t('swimmers')}
                </div>
                <div className={'number-text'}>
                    {props.swimmerCount}
                </div>
            </div>
        </div>
    )
}

LiveStatus.propTypes = {
    swimmerCount: PropTypes.number.isRequired,
    watchesCount: PropTypes.number.isRequired
};
export default LiveStatus;