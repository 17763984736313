import './SafetyEventsTile.scss'
import PropTypes from "prop-types";
import {ReactComponent as DownArrow} from "./down-arrow.svg";
import {ReactComponent as UpArrow} from "./up-arrow.svg";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const SafetyEventsTile = props => {
    const [data] = useState(props.data || {warning: 0})
    const [t] = useTranslation()
    const total = (data !== undefined ? data.warning : 0) + (data !== undefined ? data.attention : 0) + (data !== undefined ? data.overcrowding : 0)
    const radius = 70;
    const perimeterFull = Math.PI * radius * 2
    const maxThickness = 17
    const thickness = 8
    const delimiter = 15
    const circleCx = radius + thickness + 1
    const circleCy = radius + thickness + 1
    const countOfNonZeroValues = (data !== undefined && !!data.warning ? 1 : 0) + (data !== undefined && !!data.attention ? 1 : 0) + (data !== undefined && !!data.overcrowding ? 1 : 0)
    const semiWarningsLine = data.warning > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.warning / total
    const semiAttentionsLine = data.attention > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.attention / total
    const semiOvercrowdingLine = data.overcrowding > 0 && (perimeterFull - delimiter * countOfNonZeroValues) * data.overcrowding / total
    const warningsLine = ((semiWarningsLine) < 0 ? semiWarningsLine : semiWarningsLine);
    const attentionsLine = ((semiAttentionsLine) < 0 ? semiAttentionsLine : semiAttentionsLine);
    const overcrowdingLine = ((semiOvercrowdingLine) < 0 ? semiOvercrowdingLine : semiOvercrowdingLine);

    return <div id={'safety-event-tile'} className={props.isLast ? 'last' : ''}>

        <div className={'title'}>{t('safetyEvents')}</div>
        <div className={'top-container'}>
            <div className={'left-top-pane'}>
                <div className={'major-title-container'}>
                    <div className={'major-title'}>{total}</div>
                    <div className={'sub-title'}>{t('events')}</div>
                </div>
                <svg id={'svg-area'} className={'circle-chart'}>
                    <g strokeLinecap="round">
                        <svg fill="none" width="200" height="200">
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#F9F7F4"
                                    strokeWidth={maxThickness} r={radius}></circle>
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#EBEBEA"
                                    strokeWidth="1" r={radius + 8}></circle>
                            <circle className="background" fill="none" cx={circleCx} cy={circleCy} stroke="#EBEBEA"
                                    strokeWidth="1" r={radius - 8}></circle>

                            {warningsLine &&
                                <circle className="percentage" fill="none" cx={circleCx} cy={circleCy} stroke="#1F628F"
                                        strokeWidth="8" r={radius} strokeDasharray={[warningsLine, perimeterFull]}
                                        strokeDashoffset={0}></circle>}
                            {attentionsLine &&
                                <circle className="percentage" fill="none" cx={circleCx} cy={circleCy} stroke="#57A6DB"
                                        strokeWidth="8" r={radius} strokeDasharray={[attentionsLine, perimeterFull]}
                                        strokeDashoffset={-warningsLine - delimiter}></circle>}
                            {overcrowdingLine &&
                                <circle className="percentage" fill="none" cx={circleCx} cy={circleCy} stroke="#C4E0F2"
                                        strokeWidth="8" r={radius}
                                        strokeDasharray={[overcrowdingLine, perimeterFull]}
                                        strokeDashoffset={-warningsLine - attentionsLine - delimiter * (countOfNonZeroValues - 1)}></circle>}
                        </svg>
                    </g>
                </svg>
            </div>
            <div className={'right-top-pane'}>
                {data.delta < 0 && <UpArrow className={'arrow-icon'}/>}
                {data.delta > 0 && <DownArrow className={'arrow-icon'}/>}
                {data.delta < 0 &&
                    <div className={'safety-right-label'}>{`${Math.abs(data.delta)}% ${t('lessThanLastPeriod')}`}</div>}
                {data.delta > 0 &&
                    <div className={'safety-right-label'}>{`${Math.abs(data.delta)}% ${t('moreThanLastPeriod')}`}</div>}
                {data.delta === 0 &&
                    <div className={'safety-right-label'}>{`${Math.abs(data.delta)}%  ${t('sameAsLastPeriod')}`}</div>}
            </div>
        </div>
        <div className={'bottom-container'}>
            <div className={'item-row'}>
                <div className={'pre'}>
                    <div className={'bullet navy-blue'}/>
                    <div className={'item-title'}>{t('warning')}</div>
                </div>
                <div className={'item-value'}>{data !== undefined ? data.warning : 0}</div>
            </div>
            <div className={'item-row'}>
                <div className={'pre'}>
                    <div className={'bullet blue'}/>
                    <div className={'item-title'}>{t('attention')}</div>

                </div>
                <div className={'item-value'}>{data !== undefined ? data.attention : 0}</div>
            </div>
            <div className={'item-row'}>
                <div className={'pre'}>
                    <div className={'bullet light-blue'}/>
                    <div className={'item-title'}>{t('overcrowding')}</div>

                </div>
                <div className={'item-value'}>{data !== undefined ? data.overcrowding : 0}</div>
            </div>
        </div>
    </div>
}
SafetyEventsTile.propTypes = {
    data: PropTypes.shape({
        delta: PropTypes.number.isRequired,
        warning: PropTypes.number.isRequired,
        allPoolsView: PropTypes.bool,
        attention: PropTypes.number.isRequired,
        overcrowding: PropTypes.number.isRequired
    }),
    isLast: PropTypes.bool.isRequired
}
export default SafetyEventsTile