import './LivePage.scss'
import {useTranslation} from "react-i18next";
import {PropTypes} from "prop-types";
import PoolGeneralTile from "../PoolGeneralTile/PoolGeneralTile";
import {useEffect, useMemo, useState} from "react";
import ActiveWatchesTile from "../ActiveWatchesTile/ActiveWatchesTile";
import PoolDiagramTile from "../PoolDiagramTile/PoolDiagramTile";
import axios from "axios";
import {fetchSiteLiveData} from "../../api/api";
import {getNodeContext} from "../../Utils/utils";

const LivePage = props => {
    const [t] = useTranslation();
    const [data, setData] = useState(props.data || {})
    const nodeContext = useMemo(() => getNodeContext(), [])
    const setLiveData = () => {
        fetchSiteLiveData(nodeContext.siteId).then((_liveData) => {
            setData(_liveData.filter((item) => item.id === data.id)[0])
        })
    }
    useEffect(() => {
        const interval = setInterval(setLiveData, 10 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    return (
        <div id={'live-page'}>
            <div className={'main-container'}>
                {
                    data ?
                        <div key={Math.random() + '-+' + data.id}>
                            <PoolGeneralTile handleGoToPool={() => {
                            }} index={-1} key={Math.random() + data.id} poolName={data.dashboard_name}
                                             swimmersCount={data.content.locations.length}
                                             riskValue={data.content.riskLevel} capacity={data.content.capacity}
                                             isGeneral={false} isLast={false}/>
                            <ActiveWatchesTile activeWatches={data.content.activeWatches} poolId={data.id}/>
                            <PoolDiagramTile locations={data.content.locations} poolId={data.id}
                                             transformation={data.content.transformation}/>
                            <div className={'bottom-placeholder'}/>
                        </div>

                        : null
                }
            </div>
        </div>
    )
}
LivePage.propTypes = {
    data: PropTypes.object.isRequired,
    getToken: PropTypes.func.isRequired
};
export default LivePage;