import './ResetPassword.scss';
import {useTranslation} from "react-i18next";
import {useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {Button} from "@mui/material";

const ResetPassword = props => {
    const [t, tObj] = useTranslation()
    const [username, setUsername] = useState('')
    const [blankFieldAlert, setBlankFieldAlert] = useState(false)

    const handleResetPassword = async (e) => {
        if(!username) {
            setBlankFieldAlert(true);
            return;
        }
        e.preventDefault();
        props.setIsEmailSent(true);
        const url = `${process.env.REACT_APP_NODE_BE_PATH}/reset-password-token`
        const body = {username:username, fePath: window.location.href, language: tObj.language}
        try{
            await axios.post(url, body)
        }catch (e) {
            console.log("Error resetting password ", e.response)
            return  e.response.status
        }
    }
    const handleUsername = (e) => {
        setBlankFieldAlert(!e.target.value)
        setUsername(e.target.value)
    }

    return (
        <div id={'reset-password-page'}>
            <div className={'top-section'}>
                <div className={'text-pane'}>
                    <div className={'title-text'}>
                        {t('forgotPassword')}
                    </div>
                    <div className={'explain-text'}>
                        {t('enterUsernameBelow')}
                    </div>
                    <div className={'explain-text'}>
                        {t('resetPasswordExplainText')}
                    </div>
                </div>
                <div className={'sub-container'}>
                    <input className={'basic-input'} onChange={handleUsername} type="text" required/>
                    <span className={'floating-label'}>
                    {t('username')}
                </span>
                    {blankFieldAlert && <p className='username-error'>{t('resetPasswordUsernameAbsence')}</p>}
                </div>
            </div>
            <div className={'bottom-section'}>
                <Button className={'back-button'} onClick={handleResetPassword}>
                    <div className={'button-text'}>
                        {t('sendEmail')}
                    </div>
                </Button>
                <div className={'bottom-label'}>
                    <div className={'prefix'}>{t('poweredBy')}</div>
                    {'Lynxight'}
                </div>
            </div>
        </div>
    );
}
ResetPassword.propTypes = {
    setIsEmailSent: PropTypes.func
}

const ResetPasswordEmail = props => {
    const [t] = useTranslation()
    const goBack = () => {
        window.location.assign('/')
    }
    return (
        <div id='reset-password-page'>
            <div className={'top-section'}>
                <div className={'text-pane'}>
                    <div className='title-text'>
                        {t('emailSent')}
                    </div>
                    <div className='explain-text'>
                        {t('resetPasswordCodeExplainText')}
                    </div>
                    <div className='explain-text'>
                        {t('pleaseClickLink')}
                    </div>
                </div>
            </div>

            <div className={'bottom-section'}>
                <Button className={'back-button'} onClick={goBack}>
                    <div className={'button-text'}>
                        {t('backToLoginPage')}
                    </div>
                </Button>
                <div className={'bottom-label'}>
                    <div className={'prefix'}>{t('poweredBy')}</div>
                    {'Lynxight'}
                </div>
            </div>

        </div>
    );
}

const ResetPasswordFull = props => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    return (
        <div>
            {!isEmailSent &&
                <ResetPassword setIsEmailSent={setIsEmailSent}/>
            }
            {isEmailSent &&
                <ResetPasswordEmail/>
            }
        </div>
    )
}
ResetPasswordFull.propTypes = {}
export default ResetPasswordFull