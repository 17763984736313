import './DateAndTime.scss'
import React, {useState, useEffect} from 'react'
import {useTranslation} from "react-i18next";
import {getCurrentTime, languageToDateRangeFormat} from "../../Utils/utils";
import PropTypes from "prop-types";

const formatDate = 'dd MMM y | HH:mm'

const DateAndTime = props => {
    const [t, tObj] = useTranslation()
    const [dateTime, setDataTime] = useState(getCurrentTime(props.timezone, languageToDateRangeFormat[tObj.language], formatDate));

    useEffect(() => {
        setInterval(() => setDataTime(getCurrentTime(props.timezone, languageToDateRangeFormat[tObj.language], formatDate)), 1000);
    }, []);
    return <div id={'date-and-time'}>{dateTime}</div>
}
DateAndTime.propTypes = {
    timezone: PropTypes.string
}
export default DateAndTime