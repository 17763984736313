import './SwimmerHistoryTile.scss'
import PropTypes from "prop-types";
import {
    Bar,
    BarChart,
    LineChart,
    CartesianGrid,
    Line,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {DateTime} from "luxon";
import {getNodeContext, languageToDateRangeFormat} from "../../Utils/utils";

const SwimmersCountHistoryTile = props => {
    const [data] = useState(props.data && props.data.data || [])
    const [resolution] = useState(props.data && props.data.resolution)
    const [t, tObj] = useTranslation()
    const [timezone] = useState(getNodeContext().timezone)

   const parseMomentStringToDD_MM = (title) => {
        if (!title) return t('noData')
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('dd-MMM')
    }
    const getStartOfHour = (title) => {
        return title && DateTime.fromISO(title).isValid && DateTime.fromISO(title).setZone(timezone).setLocale(languageToDateRangeFormat[tObj.language]).toFormat('HH:mm')
    }


    return <div id='swimmers-count-history-tile' className={props.isLast ? 'last' : ''}>
        <div className={'container'}>
            <div className={'tile-title'}>{resolution==='days'?t('swimmersCountMax'):t('swimmersCount')}</div>
            {resolution === 'days' &&
                <ResponsiveContainer width={'100%'} height={260}>
                    <BarChart data={data} margin={{top: 35, right: 15, left: -30, bottom: 0}}>
                        <CartesianGrid strokeDasharray="14 9" vertical={false}/>
                        <XAxis tickFormatter={parseMomentStringToDD_MM} dataKey="time"/>
                        <YAxis axisLine={false} />
                        <Bar dataKey="swimmer_count" fill="#57A6DB" maxBarSize={60}/>
                    </BarChart>
                </ResponsiveContainer>
            }
            {resolution === 'hours' &&
                <ResponsiveContainer width={'100%'} height={260} >
                    <LineChart data={data} margin={{top: 35, right: 15, left: -30, bottom: 0}}>
                        <CartesianGrid strokeDasharray="14 9" vertical={false}/>
                        <XAxis interval={11} tick={{fontSize: 14}} dataKey="time" tickFormatter={getStartOfHour}/>
                        <YAxis axisLine={false}/>
                        <Line dot={false} type="monotone" dataKey="swimmer_count" stroke="#57A6DB"/>
                    </LineChart>
                </ResponsiveContainer>
            }
        </div>
    </div>
}
SwimmersCountHistoryTile.propTypes = {
    data: PropTypes.object.isRequired,
    isLast: PropTypes.bool.isRequired
}
export default SwimmersCountHistoryTile